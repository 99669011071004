// import $ from 'jquery';
// import stickyFooter from 'sticky-footer';
// import Houdini from 'houdinijs';
import SmoothScroll from 'smooth-scroll';
// import Tabby from 'tabbyjs';
import Headroom from "headroom.js";
import LazyLoad from "vanilla-lazyload";
// import 'bootstrap/js/src/dropdown';
// import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
// import tippy from 'tippy.js';
// import MicroModal from 'micromodal';
// import bsCustomFileInput from 'bs-custom-file-input';
import sidePanel from 'side-panel-menu-thing'
// import Swiper from 'swiper';
// import SwiperCore, { Navigation, Pagination, Thumbs } from 'swiper/core';
import fontSetting from './modules/font';
// import addBrowserClass from './modules/browser';
import smallDevice from './modules/small_device';

// -------------------------------------------------------------------
// stickyFooter JS
// -------------------------------------------------------------------
// stickyFooter.init({
//     selectorWrap: '[data-sticky-wrap]', // Selector for the wrap container (must use a valid CSS selector)
//     selectorFooter: '[data-sticky-footer]', // Selector for the footer (must use a valid CSS selector)
//     callback: function() {}, // Runs after the footer is stuck
// });
// -------------------------------------------------------------------
// HoudiniJS
// -------------------------------------------------------------------
// var disclosure = new Houdini('[data-houdini-group]', {
//     isAccordion: true
// });
// -------------------------------------------------------------------
// MicroModal
// -------------------------------------------------------------------
// MicroModal.init({
//     // onShow: modal => console.info(`${modal.id} is shown`), // [1]
//     // onClose: modal => console.info(`${modal.id} is hidden`), // [2]
//     // openTrigger: 'data-custom-open', // [3]
//     // closeTrigger: 'data-custom-close', // [4]
//     openClass: 'is-open', // [5]
//     disableScroll: true, // [6]
//     disableFocus: true, // [7]
//     awaitOpenAnimation: false, // [8]
//     awaitCloseAnimation: false // [9]
//         // debugMode: true // [10]
// });
// -------------------------------------------------------------------
// headroom JS
// -------------------------------------------------------------------
// if ($('.l-header').length) {
//     var header = document.querySelector('.l-header');
//     let headroom = new headroomJS(header, {
//         //上から120pxより下の領域で10px以上スクロールするとイベントが発動する
//         "offset": 100,
//         "tolerance": 10,
//         "classes": {
//             "initial": "initial"
//         },
//     });
//     headroom.init();
// } else {}
// select your header or whatever element you wish
const header = document.querySelector(".l-header");
const headroom = new Headroom(header);
headroom.init();
// (function() {
//     var header = new Headroom(document.querySelector(".l-header"), {
//         tolerance: 10,
//         offset: 100,
//         classes: {
//             initial: "initial",
//             pinned: "slideDown",
//             unpinned: "slideUp"
//         }
//     });
//     header.init();
// }());
// -------------------------------------------------------------------
// SmoothScroll JS
// -------------------------------------------------------------------
const scroll = new SmoothScroll('a[href*="#"]', {
    header: '.l-header',
    speed: 50,
    speedAsDuration: false
});
// scroll.animateScroll(80);
// -------------------------------------------------------------------
// Tippy.js
// -------------------------------------------------------------------
// new tippy('.js-tippy', {
//     theme: 'org',
//     arrow: true,
//     hideOnClick: true,
//     arrowType: 'round', // or 'sharp' (default)
//     interactive: false,
//     maxWidth: '30em'
// });
// -------------------------------------------------------------------
// side-panel-menu-thing
// -------------------------------------------------------------------
const offCanvasMenu = sidePanel({
    target: document.body,
    width: 400,
    duration: 200,
    content: document.getElementById('js-offCanvasMenu')
});
document.getElementById('js-offCanvasMenuTrigger').addEventListener('click', offCanvasMenu.show);
document.getElementById('js-offCanvasMenuHidden').addEventListener('click', offCanvasMenu.hide);
// options are accessible / changeable afterward
// offCanvasMenu.width
// offCanvasMenu.width = 500
// -------------------------------------------------------------------
// Tabby JS
// -------------------------------------------------------------------
// if ($('[genre-tabs]').length) {
//     const tabs = new Tabby('[genre-tabs]');
// } else {}
// if ($('[kana-tabs]').length) {
//     const tabs = new Tabby('[kana-tabs]');
// } else {}
// if ($('[data-tabsBd]').length) {
//     const tabsBd = new Tabby('[data-tabsBd]');
// } else {}
// if ($('[data-tabsBg]').length) {
//     const tabsBd = new Tabby('[data-tabsBg]');
// } else {}

// -------------------------------------------------------------------
// LazyLoad
// -------------------------------------------------------------------
const lazyContent = new LazyLoad({
    elements_selector: "img.lazy",
    use_native: true // <-- there you go
});
const lazyBackground = new LazyLoad({
    elements_selector: "iframe.lazy, video.lazy, div.lazy"
        // DON'T PASS use_native: true HERE
});

// -------------------------------------------------------------------
// Fancybox
// -------------------------------------------------------------------
// Fancybox.bind("[data-fancybox='book-cover']", {
//     protect: true,
//     infinite: false
// });
// -------------------------------------------------------------------
// Swiper
// -------------------------------------------------------------------
// ++++++++++++++++++++
// 書誌ページの書影エリア
// ++++++++++++++++++++
// Swiper.use([Navigation, Pagination, Thumbs]);
// var swiper = new Swiper(".js-bookCover", {
//     spaceBetween: 0,
//     navigation: {
//         nextEl: ".swiper-button-next",
//         prevEl: ".swiper-button-prev",
//     },
//     pagination: {
//         el: ".swiper-pagination",
//         clickable: false,
//     },
// });
// ++++++++++++++++++++
// TOP・カバー
// ++++++++++++++++++++
// var coverThumbs = new Swiper(".js-coverThumbs", {
//     // centeredSlides: true,
//     direction: "vertical",
//     // grabCursor: true,
//     // loop: true,
//     observer: true,
//     spaceBetween: 12,
//     slidesPerView: 3,
//     freeMode: true,
//     watchSlidesProgress: true,
//     navigation: {
//         nextEl: ".swiper-button-next",
//         prevEl: ".swiper-button-prev",
//     },
//     breakpoints: {
//         0: {
//             direction: 'horizontal',
//             slidesPerView: 5,
//             spaceBetween: 8,
//         },
//         1192: {
//             direction: 'vertical',
//         }
//     }
// });
// ++++++++++++++++++++
// TOP・カバーサムネ
// ++++++++++++++++++++
// var coverBody = new Swiper(".js-coverBody", {
//     direction: "vertical",
//     grabCursor: true,
//     observer: true,
//     loop: true,
//     pagination: {
//         el: ".swiper-pagination",
//         clickable: true,
//     },
//     thumbs: {
//         swiper: coverThumbs,
//     },
//     breakpoints: {
//         0: {
//             direction: 'horizontal',
//         },
//         944: {
//             direction: 'vertical',
//         }
//     }
// });